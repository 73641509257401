
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {ISearchField} from "@/core/entity/ISearchField";
import {OPERATOR} from "@/core/config/Constant";
import {SearchEntityEvent} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import CompanyForm from "@/views/company/CompanyForm.vue";
import Swal from "sweetalert2";
import CompanyService from "@/core/services/CompanyService";
import router from "@/router";

export default defineComponent({
  name: "Companies",
  components: {CompanyForm, BaseModal, KtDatatable, QuickAction, Entities},
  setup() {
    onMounted(() => {
      if (!store.getters.isAdmin) {
        router.push({name: "403"});
      }
      setCurrentPageBreadcrumbsWithParams("Company",
        [
          {link: false, router: '', text: 'List'}
        ]
      )
    })

    const companyModel = ref({name: '', code: '', billingAddress: '', parentId: '', fullName: ''});
    const companyId = ref('');
    const fieldSearch: ISearchField[] = [
      {column: 'clientId', type: 'HIDDEN', operator: OPERATOR.EQ, value: '', name: 'Client'},
    ]

    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Full Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Billing Address",
        key: "billingAddress",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      }
    ]);
    const companyPage = computed(() => store.state.CompanyModule.companyPage)
    return {
      ...SearchEntityEvent(Actions.LOAD_COMPANIES, fieldSearch, ['all'], ['company'], 'company'),
      tableHeader,
      companyPage,
      companyModel,
      companyId,
    }
  },

  methods: {
    onCompanySaved() {
      const baseModal = this.$refs.refCompanyModal as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad()
    },
    deleteCompany(company: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CompanyService.delete(company.id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    onEditCompany(company: any) {
      if (company === null) {
        this.companyModel = {name: '', code: '', billingAddress: '', parentId: '', fullName: ''}
        this.companyId = ''
      } else {
        this.companyId = company.id;
        this.companyModel = {
          name: company.name,
          code: company.code,
          billingAddress: company.billingAddress,
          parentId: company?.parent,
          fullName: company?.fulName
        }
      }
    }
  }
})
