import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_4 = { class: "menu-item px-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_CompanyForm = _resolveComponent("CompanyForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "view-table": true,
      label: "Companies",
      items: _ctx.companyPage.total,
      "display-view": false
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, { size: "w-150px" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                "data-bs-toggle": "modal",
                "data-bs-target": "#modalCompany",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEditCompany(null)))
              }, "New Company")
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.companyPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_KtDatatable, {
                  "table-data": _ctx.companyPage.data,
                  "table-header": _ctx.tableHeader,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.filter.paginationPage,
                  "rows-per-page": _ctx.filter.paginationSize,
                  onItemsPerPageChange: _ctx.handleUpdateSize,
                  onCurrentChange: _ctx.handleUpdatePage,
                  total: _ctx.companyPage.total
                }, {
                  "cell-name": _withCtx(({ row: company }) => [
                    _createVNode(_component_router_link, {
                      to: `/companies/`+ company.id+ '/overview'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(company.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  "cell-code": _withCtx(({ row: company }) => [
                    _createTextVNode(_toDisplayString(company.code), 1)
                  ]),
                  "cell-fullName": _withCtx(({ row: company }) => [
                    _createTextVNode(_toDisplayString(company.fullName), 1)
                  ]),
                  "cell-type": _withCtx(({ row: company }) => [
                    _createTextVNode(_toDisplayString(company.type.code), 1)
                  ]),
                  "cell-billingAddress": _withCtx(({ row: company }) => [
                    _createTextVNode(_toDisplayString(company.billingAddress), 1)
                  ]),
                  "cell-status": _withCtx(({ row: company }) => [
                    _createTextVNode(_toDisplayString(company.status.name), 1)
                  ]),
                  "cell-actions": _withCtx(({row: company}) => [
                    _createVNode(_component_QuickAction, { size: "w-150px" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3",
                            "data-bs-toggle": "modal",
                            "data-bs-target": "#modalCompany",
                            onClick: ($event: any) => (_ctx.onEditCompany(company))
                          }, "Edit", 8, _hoisted_5),
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3 text-danger",
                            onClick: ($event: any) => (_ctx.deleteCompany(company))
                          }, "Delete", 8, _hoisted_6)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["items"]),
    _createVNode(_component_BaseModal, {
      name: "New Client",
      id: "modalCompany",
      ref: "refCompanyModal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CompanyForm, {
          "company-id": _ctx.companyId,
          company: _ctx.companyModel,
          onCompanySaved: _ctx.onCompanySaved
        }, null, 8, ["company-id", "company", "onCompanySaved"])
      ]),
      _: 1
    }, 512)
  ], 64))
}